import {
  CHANGE_LAYOUT_MODE,
  RESET_VALUE,
  CHANGE_PRELOADER,
} from "./actionType";

export const changeLayoutMode = (mode) => ({
  type: CHANGE_LAYOUT_MODE,
  payload: mode,
});

export const resetValue = (value) => ({
  type: RESET_VALUE,
  payload: value,
});

export const changePreLoader = (preloader) => {
  return {
    type: CHANGE_PRELOADER,
    payload: preloader,
  };
};
